$(document).on('click', '.addService-input', function(){
    var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
  $('#new-fileds').parent().append(html);
});

$(document).on('click', '.addService-input2', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds1').parent().append(html);
});

$(document).on('click', '.addService-input3', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds3').parent().append(html);
});

$(document).on('click', '.addService-input4', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds4').parent().append(html);
});

$(document).on('click', '.addService-input5', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds5').parent().append(html);
});

$(document).on('click', '.addService-input6', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds6').parent().append(html);
});

$(document).on('click', '.addService-input7', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds7').parent().append(html);
});

$(document).on('click', '.addService-input8', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds8').parent().append(html);
});

$(document).on('click', '.addService-input9', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds9').parent().append(html);
});

$(document).on('click', '.addService-input10', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds10').parent().append(html);
});

$(document).on('click', '.addService-input11', function(){
  var html = '<div class="row mt-2"><div class="col-md-12"><input type="text" name="myInput" class="form-control"></div></div>';
$('#new-fileds11').parent().append(html);
});